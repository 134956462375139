<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Partner Management</h1>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <router-link :to="{ name: 'AddPartner' }">
        <v-btn
          rounded
          color="primary"
          class="mb-5"
          x-small
          large
          v-if="canAccess('partner-create')"
        >
          <v-icon color="white" dark>mdi-plus</v-icon
          ><span style="color: white">Add Partner</span>
        </v-btn>
      </router-link>
      <div>
        <v-row>
          <v-col cols="6"></v-col>
          <v-col cols="6">
            <b-form-input
              class="mt-6"
              style="float: right; width: 300px"
              placeholder="Search Name"
              v-model="data_search"
            ></b-form-input>
          </v-col>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Avatar</th>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <!-- <th scope="col">Description</th> -->
                    <!-- <th scope="col">Active</th> -->
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(partner, i) in partners">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td>
                        <v-img
                          v-if="partner.avatar"
                          :src="partner.avatar"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                        ></v-img>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ partner.name }}
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >
                          {{ partner.phone }}
                        </p>
                      </td>
                      <!-- <td>
                        <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{partner.description}}</p>
                      </td> -->
                      <!-- <td>
                        <p class="text-dark-75 font-weight-bolder d-block font-size-lg">{{partner.active}}</p>
                      </td> -->
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <router-link
                                :to="{
                                  name: 'EditPartner',
                                  params: { id: partner.id },
                                }"
                              >
                                <button
                                  class="btn btn-icon btn-light-primary btn-sm"
                                  v-bind="attrs"
                                  v-on="on"
                                  v-if="canAccess('partner-update')"
                                >
                                  <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                  >
                                    <v-icon color="">mdi-eye-settings</v-icon>
                                  </span>
                                </button>
                              </router-link>
                            </template>
                            <span>Detail</span>
                          </v-tooltip>
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-danger btn-sm ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="btnDeletePartner(partner.id)"
                                v-if="canAccess('partner-delete')"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-delete</v-icon>
                                </span>
                              </button>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!partners.length">
                    <tr>
                      <td style="text-align: center" colspan="7">No data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import handle_ls_search from "@/mixins/handle_ls_search.js";
export default {
  name: "ListPartner",
  mixins: [handle_ls_search],
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      partners: [],
      data_search: "",
    };
  },
  watch: {
    data_search(val) {
      this.actionSearchPartner(val);
    },
  },
  created() {
    if (this.canAccess("partner-view")) {
      let data_search = this.getDataSearchByKey("partner");
      if (data_search) {
        this.data_search = data_search.query ? data_search.query : "";
      }
      this.getAllPartner();
    } else {
      this.$router.push({ name: "403" });
    }
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    async getAllPartner(query = "") {
      let seft = this;
      await ApiService.get("prep-app/partner?name=" + query).then(function (
        response
      ) {
        if (response.status == 200) {
          seft.partners = response.data;
        }
      });
    },
    btnDeletePartner(id_partner) {
      let self = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/partner/" + id_partner)
            .then(function (res) {
              if (res.status == 202) {
                self.getAllPartner();
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    actionSearchPartner(query) {
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        let partner = {
          query: query,
        };
        vm.saveDataSearchToLs("partner", partner);
        vm.getAllPartner(query);
      }, 1200);
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.table td {
  vertical-align: middle;
}
</style>
